/* parent grid container */
.grid-container {
  display: grid;
  grid-template-columns: 240px 1fr;
  grid-template-rows: 80px 1fr 50px;
  grid-template-areas: "sidenav header" "sidenav main" "sidenav footer";
  height: 100vh;
  box-sizing: border-box;
  margin: 0; }

/* child element grid names */
.header {
  grid-area: header;
  background-color: #061322; }

.sidenav {
  grid-area: sidenav;
  background-color: #061322; }

.main {
  grid-area: main;
  background-color: #FAFBFC; }

.footer {
  grid-area: footer;
  background-color: #FAFBFC; }

/*main section*/
.main {
  margin: 15px;
  padding: 10px 20px; }

.main-header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 25px 0;
  padding: 15px 0 0 0;
  height: 50px;
  background-color: #FFFFFF;
  color: slategray; }

.main-header__sub-menu {
  font-weight: 900;
  color: black; }

/*cards*/
.main-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  /* Where the magic happens */
  grid-auto-rows: 137px;
  grid-gap: 25px;
  margin: 5px 0 20px 0; }

.overviewcard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  background-color: #FAFBFC;
  box-shadow: 0 2px 5px 0 rgba(2, 4, 63, 0.159), 0 4px 10px 0 rgba(70, 6, 62, 0.111); }

.overviewcard__info {
  float: left;
  color: grey;
  font-family: Arial, Helvetica, sans-serif;
  margin-right: 5px; }
  .overviewcard__info .figure {
    float: right;
    font-size: 24px;
    margin-top: 20px;
    line-height: 24px;
    color: black; }
  .overviewcard__info .title {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; }
    .overviewcard__info .title .apply {
      font-size: 15px;
      font-weight: 900;
      color: #061322; }

.overviewcard__icon {
  border-radius: 50px;
  height: 50px;
  width: 50px; }
  .overviewcard__icon img {
    display: block;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    height: 30px;
    width: 30px; }

.navbar {
  background-color: #061322;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center; }

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
  color: #ef233c;
  padding: 8px 4px 0 4px; }

.menu-bars:hover {
  background-color: #ef233c;
  color: antiquewhite; }

.nav-menu {
  background-color: #061322;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  padding-top: 5%;
  left: -100%;
  transition: 850ms; }

.nav-menu.active {
  left: 0;
  transition: 500ms; }

.nav-text, .logout {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0 8px 16px;
  list-style: none;
  height: 60px; }

.nav-text a {
  text-decoration: none;
  font-size: 12px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px; }

.nav-text a {
  background-color: #ffb6de1c;
  font-size: 14px;
  color: #edf2f4;
  box-shadow: 0 2px 5px 0 rgba(250, 132, 157, 0.159), 0 4px 10px 0 rgba(102, 3, 29, 0.111); }

.logout a {
  text-decoration: none;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  color: #f8e6e9;
  background-color: #d90429;
  margin-top: 200px; }

.nav-text a:hover {
  text-decoration: none;
  background-color: #FF991F;
  color: antiquewhite; }

.nav-menu-items {
  margin-top: 50px;
  width: 100%; }

.menu-icons {
  margin-right: 16px; }

.menu-icons:hover {
  color: white; }

table {
  width: 100%;
  border-collapse: collapse;
  margin: 30px auto; }

tbody {
  border-top: 1px solid #d90429;
  border-bottom: 1px solid #d90429; }

th {
  background: #FAFBFC;
  color: #061322;
  font-weight: bold;
  text-transform: uppercase; }

td, th {
  padding: 10px;
  border: 0 0 2px 0 solid #d90429;
  text-align: left;
  font-size: 14px;
  color: slategray; }

/* 
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  table {
    width: 100%; }
  /* Force table to not be like tables anymore */
  table, thead, tbody, th, td, tr {
    display: block; }
  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px; }
  tr {
    border: 1px solid #a5a6d5; }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%; }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    /* Label the data */
    content: attr(data-column);
    color: #000;
    font-weight: bold; } }

:root {
  --rad: .7rem;
  --dur: .3s;
  --color-dark: #2f2f2f;
  --color-light: rgb(22, 2, 2);
  --font-fam: 'Lato', sans-serif;
  --height: 5rem;
  --btn-width: 8rem;
  --bez: cubic-bezier(0, 0, 0.43, 1.49); }

.search-form {
  margin: 0 0 -50px auto;
  position: relative;
  width: 30rem;
  background: #d90429;
  border-radius: var(--rad); }
  .search-form input, .search-form button {
    height: var(--height);
    font-family: var(--font-fam);
    border: 0;
    color: var(--color-dark);
    font-size: 1.8rem; }
  .search-form input[type="search"] {
    outline: 0;
    width: 100%;
    background: #FAFBFC;
    box-shadow: 0 2px 5px 0 rgba(242, 120, 142, 0.159), 0 4px 10px 0 rgba(70, 6, 62, 0.111);
    padding: 0 1.6rem;
    border-radius: var(--rad);
    appearance: none;
    transition: all var(--dur) var(--bez);
    transition-property: width, border-radius;
    z-index: 0;
    position: relative; }
  .search-form button {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: var(--btn-width);
    font-weight: bold;
    background: #d90429;
    color: #FAFBFC;
    border-radius: 0 var(--rad) var(--rad) 0; }
  .search-form button:hover {
    background-color: #FF991F;
    color: antiquewhite; }
  .search-form input:not(:placeholder-shown) {
    border-radius: var(--rad) 0 0 var(--rad);
    width: calc(100% - var(--btn-width)); }
    .search-form input:not(:placeholder-shown) + button {
      display: block; }
  .search-form label {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden; }

.pending,
.approved,
.on-transit,
.delivered {
  color: #FFFFFF;
  padding: 3px 8px;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  margin: 10px 3px; }

.pending {
  background-color: #544f4f; }

.approved {
  background-color: #770202; }

.on-transit {
  background-color: #525202; }

.delivered {
  background-color: #032b03; }

.click, .reset {
  background-color: #d90429;
  font-size: 15px;
  margin-left: 8px;
  padding: 5px 8px;
  border: none;
  border-radius: 4px; }

.click {
  color: #FAFBFC;
  background-color: #d90429; }

.click:hover {
  color: #061322;
  background-color: #FF991F; }

.reset {
  color: #061322;
  background-color: #FF991F; }

.parent-container {
  border: none;
  margin: 0 20px; }

.child {
  border: none;
  border-radius: 5px;
  padding: 15px;
  background-color: #f4f5f7;
  margin: 20px; }

.child1, .child2 {
  float: left;
  border: none;
  border-radius: 5px;
  padding: 15px;
  background-color: #f4f5f7;
  margin: 20px; }

.child1 {
  width: 45%; }

.child2 {
  width: 45%; }

.user-profile {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.inner-row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 5px 15px 20px; }
  .inner-row .inner-column {
    width: 50%;
    margin: auto; }

.title {
  color: grey;
  font-size: 12px;
  margin: 8px 0; }

.table-title {
  color: grey;
  font-size: 10px; }

.title-value {
  color: black;
  font-size: 13px; }

.overviewcard__info {
  float: left;
  margin-right: 5px; }
  .overviewcard__info .figure {
    float: right;
    font-size: 24px;
    margin-top: 20px;
    line-height: 24px;
    color: black; }
  .overviewcard__info .title {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: grey; }

.profile-image {
  height: 100px;
  width: 100px; }
  .profile-image img {
    display: block;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    height: 100px;
    width: 100px;
    border-radius: 50%; }

.user-profile-btn {
  float: right;
  margin: 15px 0; }

/* side-nav styling*/
ul {
  margin: 0;
  padding: 0;
  list-style: none; }

a {
  text-decoration: none;
  color: #FFFFFF; }

li {
  list-style: none;
  text-decoration: none; }

.dashboard-logo {
  margin: 10px 0 0 40px;
  height: 110px;
  width: 130px; }

.company-identity {
  text-align: center;
  color: #FAFBFC;
  background-color: #d90429;
  padding: 10px;
  margin-bottom: 10px; }
  .company-identity h4 {
    color: #FAFBFC;
    font-size: 16px;
    border-bottom: 2px solid #FAFBFC;
    padding-bottom: 8px; }
  .company-identity p {
    font-size: 12px; }

.sidenav {
  display: flex;
  /* Will be hidden on mobile */
  flex-direction: column;
  grid-area: sidenav;
  background-color: #061322; }

.sidenav__list {
  padding: 0;
  margin-top: 50px;
  list-style-type: none;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif; }

.active-tab {
  background-color: rgba(1, 14, 7, 0.261); }

.sidenav__list-item {
  margin: 20px 20px;
  padding: 8px 10px;
  border-radius: 8px;
  color: #FFFFFF;
  box-shadow: 0 2px 5px 0 rgba(255, 192, 205, 0.159), 0 4px 10px 0 rgba(102, 3, 29, 0.111); }

.sidenav__logout-item {
  margin: 100px 20px;
  padding: 10px;
  color: #FAFBFC;
  font-size: 16px;
  background-color: #d90429;
  border-radius: 5px;
  text-decoration: none; }

.sidenav__list-item:hover {
  background-color: #FF991F;
  color: black;
  cursor: pointer;
  text-decoration: none; }

.sidenav__logout-item:hover {
  background-color: #FF991F;
  color: black;
  cursor: pointer;
  text-decoration: none; }

.sidenav-home-icon {
  position: relative;
  top: 5px;
  padding-right: 5px;
  color: #FFFFFF; }

.sidenav-icon {
  position: relative;
  padding-right: 5px;
  color: #FFFFFF; }

.down-icon {
  position: relative;
  z-index: 0;
  top: 4px;
  padding-left: 3px;
  color: grey; }

.user-icon {
  position: relative;
  top: 7px;
  margin-right: 10px;
  font-size: 25px; }

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms; }

.icon-button:hover {
  filter: brightness(1.2); }

.icon-button svg {
  fill: var(--text-color);
  width: 20px;
  height: 20px; }

.dropdown {
  z-index: 2;
  position: absolute;
  width: 200px;
  transform: translateX(45%);
  background-color: #d90429;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  transition: height 500ms ease; }

.user-profile-dropdown {
  z-index: 2;
  position: absolute;
  margin-top: 2px;
  width: 200px;
  transform: translateX(85%);
  background-color: #d90429;
  border: none;
  border-radius: 8px;
  padding: 2;
  overflow: hidden;
  transition: height 500ms ease; }

.nav-user-profile {
  margin-top: 40px; }

.user-profile-down-icon {
  position: relative;
  top: 7px;
  margin-left: 15px;
  font-size: 20px; }

.returnscard-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* Where the magic happens */
  grid-auto-rows: 300px;
  grid-gap: 20px;
  margin: 5px 0 20px 0; }

.returnscard {
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px; }

.returns-house {
  margin-top: 15px;
  padding: 10px 20px;
  display: flex;
  width: 100%;
  background: #FAFBFC;
  color: #061322;
  box-shadow: 0 2px 5px 0 rgba(250, 132, 157, 0.159), 0 4px 10px 0 rgba(102, 3, 29, 0.111); }

.returns-detail-container, .select-returns {
  display: inline-block;
  padding: 10px; }

.returns-detail-container {
  width: 90%; }

.house {
  width: 100%; }

.room {
  width: 50%;
  display: inline-block;
  padding: 10px;
  border-top: 2px solid #d90429;
  margin: 15px 0;
  line-height: 20px; }

.permit-form {
  margin: 30px 500px 30px 0; }

.company-details {
  width: 100%;
  display: inline-block;
  padding: 10px;
  border-top: 2px solid #d90429;
  border-bottom: 2px solid #d90429;
  margin: 15px 0 30 0;
  line-height: 20px; }

.terms {
  padding-left: 3px; }

textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #978f8f;
  border-radius: 4px;
  box-sizing: border-box; }

.textarea.ta10em {
  height: 10em; }

.header {
  text-align: center;
  padding-top: 10px; }
  .header h4, .header p {
    color: #FAFBFC; }

.guideline {
  margin: 80px 100px 100px 50px; }

.main-title {
  border-top: 3px solid #d90429;
  border-bottom: 3px solid #d90429; }

.main-title .sub-title {
  text-align: center;
  padding: 10px 20px; }

.list-title {
  text-decoration: underline; }

.sub-list {
  padding: 0, 10px; }

.permit-header {
  background-color: #f4f5f7;
  padding: 20px; }

.advert-card {
  position: relative;
  text-align: center;
  color: white; }

.advert-card .advert-image {
  background-size: cover;
  width: 300px;
  height: 180px; }

.completed, .ongoing, .pending {
  position: absolute;
  top: 8px;
  left: 16px;
  border: none;
  border-radius: 4px;
  padding: 4px 6px;
  font-size: 12px;
  text-transform: uppercase; }

.ongoing {
  color: #d90429;
  background-color: rgba(190, 245, 204, 0.549); }

.completed {
  color: #960d0d;
  background-color: rgba(255, 255, 255, 0.549); }

.pending {
  color: #e4a608;
  background-color: rgba(245, 237, 190, 0.549); }

.advert-details {
  margin: 15px; }

.advert-title {
  font-size: 12px;
  color: #172B4D;
  text-align: left; }

.advert-desc {
  font-size: 11px;
  color: grey;
  text-align: left;
  padding: 10px 0; }

.white-green-button {
  background-color: #d6e2dd4d;
  color: #d90429;
  border: none;
  padding: 4px 7px;
  border-radius: 2px; }

.green-white-button {
  background-color: #FFFFFF;
  color: #d90429;
  border: 1px solid #d90429;
  padding: 4px 7px;
  border-radius: 2px; }

.advert-header {
  background-color: #f4f5f7;
  padding: 20px; }

.green-text {
  color: #d90429; }

.white-text {
  color: #FFFFFF; }

.desc-title {
  margin: 25px 0;
  font-weight: bolder;
  font-size: 16px;
  text-transform: capitalize; }

.advert-desc-para {
  padding: 30px 15px;
  font-size: 14px;
  font-weight: lighter;
  color: #475264; }

.features-house {
  display: flex;
  width: 100%;
  color: slategray; }

.permit-features {
  color: black;
  font-size: 12px;
  margin: 10px 0;
  border-bottom: 2px solid #d90429; }

.features-title {
  font-size: 13px;
  color: #0b0c0b;
  display: table-cell;
  margin: -2px 3px 0 0; }

.features-desc {
  font-size: 11px;
  color: grey;
  border-left: 2px solid #d90429;
  padding-left: 5px;
  text-align: left; }

.features-card {
  float: left;
  width: 90%;
  padding: 0;
  margin: 20px;
  border: 1px solid #dce4dc58;
  box-shadow: 1px 1px 2px 1px #d2e2da87; }

.features-card .features-image {
  background-size: cover;
  width: 100%;
  height: 220px; }

.consignee-details {
  margin-left: 20%;
  padding-right: 20px; }
